import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable,  Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from '../../../environments/environment';


@Injectable()
export class LeadIpadService {

  API_BASE_URL = 'https://57urp8kz1b.execute-api.us-west-2.amazonaws.com/';

  private _freeTrialUrl = 'dev/ipadLead/freetrial';
  private _clubUrl = 'dev/ipadLead/club';
  private _sweepsUrl = 'dev/ipadLead/sweep';

  public state$ = new BehaviorSubject<any>([]);
  // public clubsList: Observable<any>;

  private selectedClubId = new Subject<string>();
  private selectedClubObject = new Subject<{}>();
  private clubFound = new Subject<boolean>();

  public clubsList$: any = this.state$.asObservable();
  // Observable string streams
  selectedClubId$ = this.selectedClubId.asObservable();
  selectedClubObject$ = this.selectedClubObject.asObservable();
  clubFound$ = this.clubFound.asObservable();

  constructor(private http: HttpClient) { }

  processSweepStakes(form, facility): Promise<any> {
    const data = {
        email: form.email, //
        firstName: form.first_name,
        lastName: form.last_name,
        facilityId: facility.acf.moso_id,
        facilityState: facility.acf.address.state,
        facilityName: facility.acf.title
    };

    const headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('x-api-key', environment.api.keyValue);

    return this.http.post(environment.api.mainUrl + '/web-blink.moso-api/createSweepstakes', data, {headers: headers}).toPromise();
}

  /**
   *
   * Observable methods
   *
   */
  updateSelectedClubObject(clubObj) {
    this.selectedClubObject.next(clubObj);
  }

  updateClubFound(isValidClub) {
    this.clubFound.next(isValidClub);
  }

  updateClubData(club) {
    this.state$.next(club);
  }

  updateSelectedClubModel(data: string) {
    this.selectedClubId.next(data);
  }

  changeState(myChange) {
    console.log('products service');
    this.state$.next(myChange);
  }

  /**
   *
   * HTTP API methods
   *
   */
  getClubList(): Observable<any> {
    return this.http.get<any>(this.API_BASE_URL + this._clubUrl).map(
      res => {
        // return res;
        return res['body'];
      }).catch((error: any) => this.handleError(error));
  }


  createFreeTrial(formObj) {
    return this.http.post(this.API_BASE_URL + this._freeTrialUrl, formObj)
      .map(
        res => {
          return res['body'];
        }).catch((error: any) => this.handleError(error));
  }

  createLead(formObj) {
    return this.http.post(this.API_BASE_URL + this._sweepsUrl, formObj)
      .map(
        res => {
          return res;
          // return res['body'];
        })
      .catch((error: any) => this.handleError(error));
  }

  validateEmailOnKickbox(email) {
    const apiKey = 'BRCjHwPk5L7YQ1G7KpuLy6SbBloGjIz6adSL1B9u';
    const emailValidateEndpoint = `https://blink-services.api.sls.blinkfitness.com/dev/validate-email/${email}`;

    return this.http.get<any>(this.API_BASE_URL + emailValidateEndpoint).map(
      res => {
        res;
      }).catch((error: any) => this.handleError(error));

  }


  /**
   *
   * Handle any Failed API method
   *
   */

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return Observable.throw(errorMessage);
  }

}
