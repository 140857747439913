import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from '../../core/shared.service';



@Injectable()
export class LocationService {
  private _locationsData: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public readonly locationsData$: Observable<any[]> = this._locationsData.asObservable().pipe(distinctUntilChanged());

  constructor(private httpClient: HttpClient, private shared: SharedService) {
    this.locationsData();
  }

  locationsData(): void {
    this.shared.showLoader();
    this.httpClient.get<any[]>(`facilities?per_page=100`).subscribe(res => {
      this.shared.hideSwal();
      this._locationsData.next(res);
    });
  }
}

