import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, ViewChild } from '@angular/core';
import { LeadIpadService } from '../services/lead-ipad.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from '../../core/shared.service';
import { NgForm } from '@angular/forms';
import { LocationService } from '../services/location.service';

@Component({
  selector: 'app-sweeps',
  templateUrl: './sweeps.component.html',
  styleUrls: ['./sweeps.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SweepsComponent implements OnInit {
  locations: Observable<any[]>;
  facilities = this._locationsService.locationsData$;
  private clubsData: any = [];
  private clubFound = false;
  sweepsFormModel: any = {};
  selectedClubObject: any = {};
  private url_param_club_name: string = undefined;
  ModalObject: any = {};
  tabHeading: String = 'Win a free membership';
  @Output() tabHeadingEvent = new EventEmitter<string>();
  @ViewChild('sweepsForm') sweepsForm: NgForm;

  constructor(
    private _route: ActivatedRoute,
    private shared: SharedService,
    private clubsApi: LeadIpadService,
    private _locationsService: LocationService
  ) {
  }

  ngOnInit() {
    const param = this._route.snapshot.paramMap.get('club_name');
    if (param) {
      this.url_param_club_name = param;
    }



    // this.clubsApi.getClubList().subscribe(data => {

    //   this.clubsData = this.clubsApi.clubsList$ = data;
    //   this.clubsApi.updateClubData(this.clubsData);
    //   if (param) {
    //     this.setDefaultSelectedClub(param);
    //   }
    // }, error => {
    //   this.shared.hideSwal();
    //   this.displayErrorDialog();
    //   console.log(error);
    // });


    this.clubsApi.selectedClubId$.subscribe(data => {
      this.sweepsFormModel.selectedClubValue = data;
    });

    this.clubsApi.selectedClubObject$.subscribe(data => {
      this.selectedClubObject = data;
      this.changeTabNameAndHeading();
    });


  }


  /*
    Set default selected Club if Url has value of club
  */

  setDefaultSelectedClub(club_name) {
    const clubs = this.clubsData;

    for (const c of clubs) {
      if (c['shortName'].toLowerCase() === club_name.toString().toLowerCase()) {
        this.clubsApi.updateSelectedClubModel(c['clubId']);
        this.clubFound = true;
        this.clubsApi.updateClubFound(this.clubFound);
        break;
      }
    }

  }


  /*
    Update other Components select value here
  */
  changeSelectedClub(newValue) {
    this.clubsApi.updateSelectedClubModel(newValue);
  }

  resetSweepsForm() {
    this.sweepsForm.resetForm();
    if (this.url_param_club_name !== undefined) {
      setTimeout(() => {
        this.setDefaultSelectedClub(this.url_param_club_name);
      }, 100);
    }

  }

  /**
   *
   * This @function would be when the selected Club is Opened club
   *
   */

  submitLeadForm(form_data) {
    this.shared.showLoader();
    this.clubsApi.createLead(form_data).subscribe(
      data => {
        this.shared.hideSwal();
        if (data['success']) {
          // alert("Success");
          this.ModalObject.title = 'Thanks!';
          // var details = data['details'];

          const bodyHtml = `< h4 > Thanks! < /h4>
            < p > We will send you an email notifying you when your club opens!</p> `;

          this.ModalObject.body = bodyHtml;
          this.resetSweepsForm();
          // Reset Form here
        } else {
          this.displayErrorDialog();
        }
      },
      error => {
        this.shared.hideSwal();
        this.displayErrorDialog();
        console.error(error);
      });

  }

  /**
   *
   * This @function would be when the selected Club is Sweeps or presales club
   *
   */
  submitTrialForm(form_data) {
    this.shared.showLoader();
    this.clubsApi.createFreeTrial(form_data).subscribe(
      data => {
        this.shared.hideSwal();
        if (data['success']) {
          // alert("Success");
          this.ModalObject.title = data['details']['title'];
          const details = data['details'];

          const bodyHtml = `< p > ${details['subtitle']} </p> <h4> redeem at: </h4 > <div id="club-address" >
          <div>
          <h5>${ details['clubInformation']['name']} </h5>
            < /div>
            < div > ${ details['clubInformation']['address1']} </div>
              < div > ${ details['clubInformation']['address2']} </div>
                < div id = "club-phone" >
                  <a href="tel:${details['clubInformation']['address3']}" > ${details['clubInformation']['address3']} </a>
                    < /div>
                    < /div>
                    < div id = "club-hours" >
                      <b>hours.< /b>
                      < div class="hours-container" >
                        <div class="hours-row" >
                          <div>${ details['timings']} </div>
                            < /div>
                            < /div>
                            < /div> `;

          this.ModalObject.body = bodyHtml;
          // Reset Form here
          this.resetSweepsForm();
        } else {
          this.displayErrorDialog();
        }
      },
      error => {
        this.shared.hideSwal();
        this.displayErrorDialog();
        console.error(error);
      });
  }

  displayErrorDialog() {
    this.ModalObject.title = 'Error';
    this.ModalObject.body = '<p>Looks like there was an error. Take a deep breath and try again?</p>';
    this.ModalObject.displayButton = true;
  }

  changeTabNameAndHeading() {
    if (this.selectedClubObject['isPresale']) {
      this.tabHeading = 'WIN A FREE MEMBERSHIP';
      this.tabHeadingEvent.emit('Sweeps');
    } else {
      this.tabHeading = 'GET YOUR FREE TRIAL TODAY';
      this.tabHeadingEvent.emit('Trial');
    }
  }


  submitSweepsForm() {
    const data = {
      emailAddress: this.sweepsFormModel.email,
      facilityId: this.sweepsFormModel.selectedClubValue,
      firstName: this.sweepsFormModel.firstname,
      lastName: this.sweepsFormModel.lastname,
      state: this.selectedClubObject.state,
      isClubPage: false
    };

    if (this.selectedClubObject['isPresale']) {
      this.submitLeadForm(data);
    } else {
      this.submitTrialForm(data);
    }

  }

  invalidEmailAddressMsg(email) {
    this.ModalObject = {};
    this.ModalObject.displayButton = true;
    const bodyHtml = `
                      Unfortunately, the email you entered ${email} appears to be invalid.
                      Please check the spelling of the email and try again.
                      `;
    this.ModalObject.body = bodyHtml;
  }

  validateEmailAddress() {
    const email = '';
    this.clubsApi.validateEmailOnKickbox(email).subscribe(
      data => {

        if (data['success']) {
          if (data['result'] === 'unknown' || data['result'] === 'undeliverable' || data['reason']
            === 'invalid_domain' || data['reason'] === 'invalid_email' || data['reason'] === 'rejected_email') {
            // display Email invalid message
            this.invalidEmailAddressMsg(this.sweepsFormModel.email);

          } else {
            // Submit the Form here
            const formStatus = (data['result'] === 'deliverable') ? 'Successful' : 'Unknown';
            this.submitSweepsForm();
          }

        } else {
          console.log('Error in email validate API');
          // triggerAnalytics( leadCapture.formData()['facilityId'], submitionType, "API limit reached" );
          this.displayErrorDialog();
        }

      }, error => {
        this.displayErrorDialog();
      }
    );
  }
}



