import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from './../core/shared.service';
declare var $: any;

@Component({
  selector: 'app-ipad-lead',
  templateUrl: './ipad-lead.component.html',
  styleUrls: ['./ipad-lead.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IpadLeadComponent implements OnInit {

  tabHeading: String = 'Sweeps';

  constructor(private _route: ActivatedRoute, private _router: Router, private shared: SharedService) { }

  ngOnInit() { }

  updateHeading($event) {
    this.tabHeading = $event;
  }

  showSuccess() {
    this.shared.showSuccess('We will send you an email notifying you when your club opens!');
  }

  showError() {
    this.shared.showError('Looks like something is wrong..');
  }

}
