import { Component, OnInit } from '@angular/core';
import { SharedService } from './core/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'iPad Lead';

  constructor(private shared: SharedService) { }

  ngOnInit() { }

}
