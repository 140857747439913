import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { SharedService } from './shared.service';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  constructor(private shared: SharedService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let _req;
    const reqUrl = req.url;
    let baseUrl = environment.api.mainUrl;
    if (reqUrl.indexOf('facilities') !== -1) {
      baseUrl = environment.api.cmsUrl;
    }

    _req = req.clone({
      url: baseUrl + reqUrl,
      headers: req.headers
        .set('Content-Type', environment.api.contentType)
        .set(environment.api.keyName, environment.api.keyValue)
    });

    return next.handle(_req).pipe(
      tap((event: any) => { },
        (error: any) => {
          console.log(JSON.stringify(error));
          if (error instanceof HttpErrorResponse) {
            this.shared.hideSwal();
            let errorText = error.error.message;
            if (error.statusText === 'Unknown Error') {
              errorText = 'An unexpected error occurred.';
            }
            this.shared.showError(errorText);
          }
        })
    );
  }
}
