import { Component, OnInit } from '@angular/core';
import { LeadIpadService } from '../services/lead-ipad.service';
import { Observable } from 'rxjs/Observable';
import { LocationService } from '../services/location.service';

@Component({
    selector: 'app-maps',
    templateUrl: './maps.component.html',
    styleUrls: ['./maps.component.scss'],
})
export class MapsComponent implements OnInit {
    locations: Observable<any[]>;
    facilities = this._locationsService.locationsData$;
    private clubsData = [];
    mapsFormModel: any = {};
    selectedClubObject = {};
    private clubs$: Observable<any>;
    private clubFound = false;
    floor_plan: string;

    constructor(private clubsApi: LeadIpadService, private _locationsService: LocationService) {
        this.getData(this.mapsFormModel);
    }

    ngOnInit() {
        this.clubsApi.clubsList$.subscribe(data => {
            this.clubsData = data;
        });

        this.clubsApi.selectedClubId$.subscribe(data => {
            this.mapsFormModel.selectedClubValue = data;
            this.findSelectedClub(data);
            this.getData(data);
        });

        this.clubsApi.clubFound$.subscribe(data => {
            this.clubFound = data;
        });

    }

    changeSelectedClub(newValue) {
        this.clubsApi.updateSelectedClubModel(newValue);
        this.getData(newValue);
    }

    getData(name) {
        this.facilities.subscribe(res => {
            if (res) {
                res.forEach(item => {
                    if (item.acf.title === name) {
                        this.floor_plan = item.acf.floor_plan.floor_plan_image;
                    }
                });
            }
        });
    }

    findSelectedClub(clubId: String) {
        this.selectedClubObject = {};
        if (clubId) {

            for (const obj of this.clubsApi.clubsList$) {
                if (obj.clubId === clubId) {
                    this.selectedClubObject = obj;
                    break;
                }
            }
        }
        this.clubsApi.updateSelectedClubObject(this.selectedClubObject);

        return this.selectedClubObject;
    }
}
