import { Component, OnInit } from '@angular/core';
declare var jwplayer: any;

@Component({
  selector: 'app-jwplayer',
  templateUrl: './jwplayer.component.html',
  styleUrls: ['./jwplayer.component.scss']
})
export class JwplayerComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    const playerJw = jwplayer('jwplayer').setup({
      title: 'Blink Fitness',
      image: "/assets/img/dronetour.jpg",
      file: 'http://content.jwplatform.com/videos/WErNiD8D-ivNG7VTE.mp4',
      width: '100%',
      //height: 'auto',
      aspectratio: '16:9',
      mute: false,
      repeat: false,
      autostart: false,
      primary: 'html5',
    });


  }

}
