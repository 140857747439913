import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

/**
 * Shared
 */

@Injectable()
export class SharedService {
  balls = '<div class="balls">' +
    '<span class="bg-orange"></span>' +
    '<span class="bg-blue"></span>' +
    '<span class="bg-green-light"></span><div>';

  showSuccess(message) {
    swal({
      title: 'Thanks!',
      type: 'success',
      html: message,
      position: 'top',
      allowOutsideClick: false,
      confirmButtonText: 'Exit',
      confirmButtonAriaLabel: 'Exit'
    }).then(() => {
      console.log('thanks');
      document.body.classList.remove('swal-success');
    });
    document.body.classList.add('swal-success');
  }

  showError(message) {
    swal({
      title: 'alert!',
      type: 'error',
      text: message,
      position: 'top',
      allowOutsideClick: false,
      confirmButtonText: 'Try again',
      confirmButtonAriaLabel: 'Try again'
    }).then(() => {
      document.body.classList.remove('swal-error');
    });
    document.body.classList.add('swal-error');
  }

  showInfo(title, message, buttonText) {
    swal({
      title: title,
      type: 'info',
      html: message,
      position: 'top',
      allowOutsideClick: false,
      confirmButtonText: buttonText,
      confirmButtonAriaLabel: buttonText
    });
  }

  showLoader() {
    document.body.classList.add('swal-loading');
    swal({
      type: 'info',
      animation: false,
      showConfirmButton: false,
      allowOutsideClick: false
    });
  }

  hideSwal() {
    document.body.classList.remove('swal-loading');
    swal.close();
  }

}
